@import "../variables";
@import "~bootstrap/scss/mixins/breakpoints";

html,
body {
    height: 100%;
    font-family: "Montserrat", sans-serif;
}

.mt-minus-6 {
    margin-top: -6.2rem;
}

header {
    text-align: center;
    height: 100%;
    position: relative;
    overflow: hidden;
    margin-top: 0;
    padding-top: 0;

    .fixed-top {
        top: 0 !important;

        @include media-breakpoint-up(md) {
            top: 39px !important;
        }
    }

    .header-logo {
        position: relative;
        z-index: 2;
        width: 133px;
    }

    h1 {
        position: relative;
        z-index: 2;
        font-size: 1.5rem;
        line-height: 1.4;
        color: $primary;
    }

    p {
        position: relative;
        z-index: 2;
        font-size: 1rem;
        line-height: 1.35;
        font-weight: 600;
    }

    a {
        position: relative;
        z-index: 2;
    }

    @include media-breakpoint-up(sm) {
        h1 {
            font-size: 2rem;
            line-height: 1.4;
        }

        p {
            font-size: 1rem;
            line-height: 1.2;
        }
    }

    @include media-breakpoint-up(md) {
        h1 {
            font-size: 2.25rem;
            line-height: 1.4;
        }

        .header-logo {
            width: 233px;
        }
    }

    @include media-breakpoint-up(lg) {
        h1 {
            font-size: 2.5rem;
            line-height: 1.4;
        }

        p {
            font-size: 1.15rem;
            line-height: 1.35;
        }
    }

    @include media-breakpoint-up(xxl) {
        h1 {
            font-size: 3rem;
            line-height: 1.4;
        }

        p {
            font-size: 1.35rem;
            line-height: 1.35;
        }
    }
}

.error-background {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 810px;
    background: url("../images/v2/404.webp");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    z-index: 1;

    img {
        max-height: 810px !important;
    }

    .btn-error {
        font-size: 12px;
        font-weight: 800;
        margin-top: -104px;
        padding-left: 24px;
        padding-right: 24px;
        min-width: 124px;

        @include media-breakpoint-up(md) {
            font-size: max(12px, min(5vw, 20px));
            min-width: 224px;
        }
    }
}
